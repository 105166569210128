<script>
import { required, email } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { parseErrors} from "@/helpers"
import { mapGetters, mapActions } from 'vuex';

export default {
 setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      email: null,
      password: null,
      errors: [],
      accounts: [],
      accountId: null
    };
  },
  validations() {
    const rules = {
      email: { required, email },
      password: { required }
    };

    if(this.accounts && this.accounts.length > 0) {
      rules.accountId = { required };
    }
   
    return rules;
  },
  computed :{
    ...mapGetters('auth', {
      processing: 'processing'
    }),
  },
  methods: {
    ...mapActions('auth', {
      logIn: 'logIn'
    }),
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        const { password, email, accountId } = this;
        this.logIn({ password, email, accountId })
          .then((data) => {
       
            if(data === true) {
              this.$router.push( this.$route.query.redirectFrom || { name: "home" });
            } else {
              this.accounts = data || [];
            }
          })
          .catch(error => {
            console.error(error);
            this.errors = parseErrors(error);
          });
      }
    }
  }
}

</script>

<template>
  <div class="auth-page">
    <div class="container-fluid p-0">
      <div class="row g-0 align-items-center">
        <div class="col-xxl-4 col-lg-4 col-md-6">
          <div class="row justify-content-center g-0">
              <div class="col-xl-9">
                <div class="p-4">
                  <div class="card mb-0">
                    <div class="card-body">
                      <div class="auth-full-page-content rounded d-flex p-3 my-2">
                        <div class="w-100">
                          <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5">
                              <router-link to="/" class="d-block auth-logo">
                                <img src="@/assets/images/logo-dark.png" alt="" height="22" class="auth-logo-dark me-start">
                                <img src="@/assets/images/logo-light.png" alt="" height="22" class="auth-logo-light me-start">
                              </router-link>
                            </div>
                            <div class="auth-content my-auto">
                              <div class="text-center">
                                <h5 class="mb-0">Welcome Back !</h5>
                                <p class="text-muted mt-2">Sign in to continue to VostokSOS CRM.</p>
                              </div>
                              <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
                                <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
                                <ul class="list-unstyled">
                                  <li v-for="e in errors" :key="e">{{e}}</li>
                                </ul>
                              </div>
                              <form class="mt-4 pt-2">
                                <div class="mb-4">
                                  <label for="input-username"  class="form-label">Email</label>
                                  <input type="text" class="form-control" id="input-username" placeholder="Enter Email" v-model="email" :class="{ 'is-invalid': v$.email.$error }" :disabled="accounts.length > 0" :readonly="accounts.length > 0"/>   
                                  <div class="invalid-feedback">
                                    Please Enter Email
                                  </div> 
                                </div>

                                <div class=" mb-4">
                                  <label for="password-input" class="form-label">Password</label>
                                  <input type="password" class="form-control" id="password-input" placeholder="Enter Password" v-model="password"  :class="{ 'is-invalid': v$.password.$error }"/>
                                  <div class="invalid-feedback">
                                    Please Enter Password
                                  </div>
                                </div>
                                <div class="alert mt-2 mb-2 alert-info" v-if="accounts.length > 0">
                                  We found that your Email is associated with more than one account. Please choose an account you want to login.
                                </div>
                                <div class=" mb-4"  v-if="accounts.length > 0">
                                  <label for="account-input" class="form-label">Account</label>
                                  <select class="form-control" id="account-input" placeholder="Please Select Account" v-model="accountId"  :class="{ 'is-invalid': v$.accountId.$error }">
                                    <option :value="null">--- Please Select Account ---</option>
                                    <option v-for="account in accounts" :key="account.id" :value="account.id">{{account.text}}</option>
                                  </select>
                                  <div class="invalid-feedback">
                                    Please Select Account
                                  </div>
                                </div>
                                

                                <div class="mb-3">
                                  <button class="btn btn-primary w-100 waves-effect waves-light" type="button" @click="submit" :disabled="processing">
                                    <span v-if="processing">
                                      <i class="fa fa-spin fa-spinner mr-2"/>
                                    </span>
                                    Log In
                                  </button>
                                </div>
                              </form>

                              <div class="mt-4 pt-3 text-center">
                                <p class="text-muted mb-0">Don't have an account ? <router-link to="/register" class="text-primary fw-semibold"> Register now </router-link> </p>
                              </div>
                              <div class="mt-2 text-center">
                                <p class="text-muted mb-0">Forgot Password? <router-link to="/recoverpwd" class="text-primary fw-semibold"> Reset password </router-link> </p>
                              </div>
                            </div>
                            <div class="mt-4 text-center">
                              <p class="mb-0">© {{new Date().getFullYear()}} VostokSOS CRM. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <!-- end auth full page content -->
        </div>
        <!-- end col -->
        <div class="col-xxl-8 col-lg-8 col-md-6">
          <div class="auth-bg bg-white py-md-5 p-4 d-flex">
            <div class="bg-overlay bg-white"></div>
            <!-- end bubble effect -->
            <div class="row justify-content-center align-items-center">
              <div class="col-xl-8">
                <div class="mt-4">
                  <img src="@/assets/images/login-img.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container fluid -->
  </div>
</template>